import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Link, useIntl, FormattedMessage } from 'gatsby-plugin-intl';

import Alert from '../../components/Alert/Alert';
import Layout from '../../components/Layouts/layout';
import SEO from '../../components/seo';
import LinkList from '../../components/LinkList/LinkList';

const DisciplineAppealsPage = () => {
	const intl = useIntl();

	return (
		<Layout
			header={<FormattedMessage id='daCommittee.headerI' />}
			breadcrumb={{
				text: <FormattedMessage id='nav.about' />,
				path: '/about/'
			}}
		>
			<SEO
				description={intl.formatMessage({
					id: 'metaTags.aboutDescription'
				})}
				lang={intl.locale}
				title={intl.formatMessage({ id: 'daCommittee.pageTitle' })}
			/>
			<Container>
				<Row>
					<Col md={12}>
						<h2>
							<FormattedMessage id='dacPage.headerA' />
						</h2>
						<p>
							<FormattedMessage id='dacPage.contentAi' />
							<a
								href={intl.formatMessage({
									id: 'dacPage.linkA'
								})}
							>
								<FormattedMessage id='dacPage.contentAii' />
							</a>
							<FormattedMessage id='dacPage.contentAiii' />
						</p>

						<p>
							<FormattedMessage id='dacPage.contentAiv' />{' '}
							<a
								href={intl.formatMessage({
									id: 'dacPage.linkB'
								})}
							>
								<FormattedMessage id='dacPage.contentAv' />
							</a>{' '}
							<FormattedMessage id='dacPage.contentAvi' />{' '}
							<a
								href={intl.formatMessage({
									id: 'dacPage.linkA'
								})}
							>
								<FormattedMessage id='dacPage.contentAii' />
							</a>{' '}
							<FormattedMessage id='dacPage.contentAviii' />
						</p>

						<p>
							<FormattedMessage id='dacPage.contentAix' />
						</p>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col>
						<h2>
							<FormattedMessage id='daCommittee.headerI' />
						</h2>
						<p>
							<FormattedMessage id='daCommittee.contentI' />
						</p>
						<p>
							<FormattedMessage id='dacPage.contentBiv' />
							<ul>
								<li>
									<FormattedMessage id='dacPage.list01a' />
								</li>
								<li>
									<FormattedMessage id='dacPage.list01b' />
								</li>
								<li>
									<FormattedMessage id='dacPage.list01c' />
								</li>
								<li>
									<FormattedMessage id='dacPage.list01h' />
								</li>
								<li>
									<FormattedMessage id='dacPage.list01d' />
								</li>
								<li>
									<FormattedMessage id='dacPage.list01e' />
								</li>
							</ul>
						</p>
						<p>
							<FormattedMessage id='dacPage.contentF' />
						</p>

						<p>
							<FormattedMessage id='dacPage.list01f' />{' '}
							<a
								href={intl.formatMessage({
									id: 'dacPage.link01g'
								})}
							>
								<FormattedMessage id='dacPage.list01g' />
							</a>
							.
						</p>
					</Col>
				</Row>
				<Row className='pageRow'>
					<Col md={12} lg={12}>
						<h2>
							<FormattedMessage id='daCommittee.headerK' />
						</h2>
					</Col>
					<Col sm={6}>
						<ul style={{ marginTop: `0`, marginBottom: `0` }}>
							<li>
								Amanda Lazaridis <FormattedMessage id='daCommittee.licensee' />
							</li>
							<li>
								Nathan Kok <FormattedMessage id='daCommittee.licensee' />
							</li>
							<li>
								Emily Li <FormattedMessage id='daCommittee.licensee' />
							</li>
							<li>
								Shailey Singh <FormattedMessage id='daCommittee.licensee' />
							</li>
							<li>
								Francesca Jacobs <FormattedMessage id='daCommittee.licensee' />
							</li>
							<li>
								Yousif Kazandhi <FormattedMessage id='daCommittee.licensee' />
							</li>
						</ul>
					</Col>
					<Col sm={6}>
						<ul style={{ marginTop: `0`, marginBottom: `0` }}>
							<li>
								Yaroslav Bilyk <FormattedMessage id='daCommittee.licensee' />
							</li>
							<li>
								Sylvia Kasparek <FormattedMessage id='daCommittee.licensee' />
							</li>
							<li>
								Claude Lloyd <FormattedMessage id='daCommittee.public' />
							</li>
							<li>
								Lisa Key <FormattedMessage id='daCommittee.public' />
							</li>
							<li>
								Daniela Corapi <FormattedMessage id='daCommittee.public' />
							</li>
						</ul>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col>
						<h3>
							<FormattedMessage id='board.chair' />
						</h3>
						<p>
							Alisa Chaplick <FormattedMessage id='daCommittee.public' />
						</p>

						<h3>
							<FormattedMessage id='board.viceChair' />
						</h3>
						<p>
							Graham Clarke <FormattedMessage id='daCommittee.public' />
						</p>
						<h3>
							<FormattedMessage id='board.chair' />
						</h3>
						<p>
							Guy Caverson <FormattedMessage id='daCommittee.licensee' />
						</p>

						<h3>
							<FormattedMessage id='board.viceChair' />
						</h3>
						<p>Vacant</p>
					</Col>
				</Row>

				<Row>
					<Col>
						<h2>
							<FormattedMessage id='dacPage.headerC' />
						</h2>
						<p>
							<FormattedMessage id='dacPage.contentCi' />{' '}
							<a href='mailto:hearings@hcraontario.ca'>hearings@hcraontario.ca</a>{' '}
							<FormattedMessage id='dacPage.contentCii' />
						</p>
					</Col>
				</Row>
				<Row className='pageRow'>
					{intl.locale === 'en' && (
						<Col>
							<h2>
								<FormattedMessage id='dacPage.headerD' />
							</h2>
							<p>
								<FormattedMessage id='dacPage.contentDi' />
							</p>
							<p>
								<FormattedMessage id='dacPage.contentDi1' />
							</p>
							<table>
								<thead>
									<th>Date</th>
									<th colSpan={3}>Meetling Link</th>
								</thead>
								<tbody>
									<tr>
										<td><FormattedMessage id='dacPage.meetingDate1' /></td>
										<td>
										<span><FormattedMessage id='dacPage.contentMeetingProvider' /></span> <a href={intl.formatMessage({ id: 'dacPage.meetingLink1' })}>
											<FormattedMessage id='dacPage.meetingLink1' />
										</a> <br />
										<span><FormattedMessage id='dacPage.contentMeetingIDLabel' /></span> <FormattedMessage id='dacPage.meetingID1' /> <br />
										<span><FormattedMessage id='dacPage.contentMeetingPasscodeLabel' /></span> <FormattedMessage id='dacPage.meetingPasscode1' />
										</td>
									</tr>
									<tr>
										<td><FormattedMessage id='dacPage.meetingDate2' /></td>
										<td>
										<span><FormattedMessage id='dacPage.contentMeetingProvider' /></span> <a href={intl.formatMessage({ id: 'dacPage.meetingLink2' })}>
											<FormattedMessage id='dacPage.meetingLink2' />
										</a> <br />
										<span><FormattedMessage id='dacPage.contentMeetingIDLabel' /></span> <FormattedMessage id='dacPage.meetingID2' /> <br />
										<span><FormattedMessage id='dacPage.contentMeetingPasscodeLabel' /></span> <FormattedMessage id='dacPage.meetingPasscode2' />
										</td>
									</tr>
									<tr>
										<td><FormattedMessage id='dacPage.meetingDate3' /></td>
										<td>
										<span><FormattedMessage id='dacPage.contentMeetingProvider' /></span> <a href={intl.formatMessage({ id: 'dacPage.meetingLink3' })}>
											<FormattedMessage id='dacPage.meetingLink3' />
										</a> <br />
										<span><FormattedMessage id='dacPage.contentMeetingIDLabel' /></span> <FormattedMessage id='dacPage.meetingID3' /> <br />
										<span><FormattedMessage id='dacPage.contentMeetingPasscodeLabel' /></span> <FormattedMessage id='dacPage.meetingPasscode3' />
										</td>
									</tr>
									<tr>
										<td><FormattedMessage id='dacPage.meetingDate4' /></td>
										<td>
										<span><FormattedMessage id='dacPage.contentMeetingProvider' /></span> <a href={intl.formatMessage({ id: 'dacPage.meetingLink4' })}>
											<FormattedMessage id='dacPage.meetingLink4' />
										</a> <br />
										<span><FormattedMessage id='dacPage.contentMeetingIDLabel' /></span> <FormattedMessage id='dacPage.meetingID4' /> <br />
										<span><FormattedMessage id='dacPage.contentMeetingPasscodeLabel' /></span> <FormattedMessage id='dacPage.meetingPasscode4' />
										</td>
									</tr>
									<tr>
										<td><FormattedMessage id='dacPage.meetingDate5' /></td>
										<td>
										<span><FormattedMessage id='dacPage.contentMeetingProvider' /></span> <a href={intl.formatMessage({ id: 'dacPage.meetingLink5' })}>
											<FormattedMessage id='dacPage.meetingLink5' />
										</a> <br />
										<span><FormattedMessage id='dacPage.contentMeetingIDLabel' /></span> <FormattedMessage id='dacPage.meetingID5' /> <br />
										<span><FormattedMessage id='dacPage.contentMeetingPasscodeLabel' /></span> <FormattedMessage id='dacPage.meetingPasscode5' />
										</td>
									</tr>
									<tr>
										<td><FormattedMessage id='dacPage.meetingDate6' /></td>
										<td>
										<span><FormattedMessage id='dacPage.contentMeetingProvider' /></span> <a href={intl.formatMessage({ id: 'dacPage.meetingLink6' })}>
											<FormattedMessage id='dacPage.meetingLink6' />
										</a> <br />
										<span><FormattedMessage id='dacPage.contentMeetingIDLabel' /></span> <FormattedMessage id='dacPage.meetingID6' /> <br />
										<span><FormattedMessage id='dacPage.contentMeetingPasscodeLabel' /></span> <FormattedMessage id='dacPage.meetingPasscode6' />
										</td>
									</tr>
									<tr>
										<td><FormattedMessage id='dacPage.meetingDate7' /></td>
										<td>
										<span><FormattedMessage id='dacPage.contentMeetingProvider' /></span> <a href={intl.formatMessage({ id: 'dacPage.meetingLink7' })}>
											<FormattedMessage id='dacPage.meetingLink7' />
										</a> <br />
										<span><FormattedMessage id='dacPage.contentMeetingIDLabel' /></span> <FormattedMessage id='dacPage.meetingID7' /> <br />
										<span><FormattedMessage id='dacPage.contentMeetingPasscodeLabel' /></span> <FormattedMessage id='dacPage.meetingPasscode7' />
										</td>
									</tr>
									<tr>
										<td><FormattedMessage id='dacPage.meetingDate8' /></td>
										<td>
										<span><FormattedMessage id='dacPage.contentMeetingProvider' /></span> <a href={intl.formatMessage({ id: 'dacPage.meetingLink8' })}>
											<FormattedMessage id='dacPage.meetingLink8' />
										</a> <br />
										<span><FormattedMessage id='dacPage.contentMeetingIDLabel' /></span> <FormattedMessage id='dacPage.meetingID8' /> <br />
										<span><FormattedMessage id='dacPage.contentMeetingPasscodeLabel' /></span> <FormattedMessage id='dacPage.meetingPasscode8' />
										</td>
									</tr>
									<tr>
										<td><FormattedMessage id='dacPage.meetingDate9' /></td>
										<td>
										<span><FormattedMessage id='dacPage.contentMeetingProvider' /></span> <a href={intl.formatMessage({ id: 'dacPage.meetingLink9' })}>
											<FormattedMessage id='dacPage.meetingLink9' />
										</a> <br />
										<span><FormattedMessage id='dacPage.contentMeetingIDLabel' /></span> <FormattedMessage id='dacPage.meetingID9' /> <br />
										<span><FormattedMessage id='dacPage.contentMeetingPasscodeLabel' /></span> <FormattedMessage id='dacPage.meetingPasscode9' />
										</td>
									</tr>
									<tr>
										<td><FormattedMessage id='dacPage.meetingDate10' /></td>
										<td>
										<span><FormattedMessage id='dacPage.contentMeetingProvider' /></span> <a href={intl.formatMessage({ id: 'dacPage.meetingLink10' })}>
											<FormattedMessage id='dacPage.meetingLink10' />
										</a> <br />
										<span><FormattedMessage id='dacPage.contentMeetingIDLabel' /></span> <FormattedMessage id='dacPage.meetingID10' /> <br />
										<span><FormattedMessage id='dacPage.contentMeetingPasscodeLabel' /></span> <FormattedMessage id='dacPage.meetingPasscode10' />
										</td>
									</tr>
								</tbody>
							</table>
						</Col>
					)}
				</Row>
				<Row className='pageRow'>
					<Col>
						<h2>
							<FormattedMessage id='dacPage.headerE' />
						</h2>
						<p>
							<FormattedMessage id='dacPage.contentEi' /> <FormattedMessage id='dacPage.contentEii' />{' '}
							<Link to='/licensing-compliance/possible-courses-of-action/'>
								<FormattedMessage id='dacPage.contentEiii' />
							</Link>{' '}
							<FormattedMessage id='dacPage.contentEiv' />
						</p>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};
export default DisciplineAppealsPage;
